import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import { Autocomplete, IconButton, InputAdornment, TextField } from '@mui/material';
import { Lead as LeadType } from 'src/views/Leads/types';
import Search from 'src/template/material-kit-pro/icons/Search';
import partnerApi from 'src/api';
import useStyles from './AssignLeadModal.styles';

interface AssignLeadModalInterface {
  lead: LeadType;
  editVariant?: boolean;
  showSkip?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export default function AssignLeadModal({ lead, isOpen, showSkip = false, editVariant = false, onClose }: AssignLeadModalInterface) {
  const [selectedPerson, setSelectedPerson] = useState(null);
  const classes = useStyles({ showSkip });
  const leadDetailsPath = '/lead/:leadId'.replace(':leadId', lead?.leadId);

  // Fetch assignable users
  const { data: assignableUsers, isLoading } = partnerApi('getAssignableUsers', {}, lead ? true : false);

  // Set title and update label based on modal variant
  const modalTitle = editVariant ? 'Edit assignee' : 'Add assignee';
  const modalDescription = editVariant ? 'Update the salesperson assigned to this lead' : 'Assign a salesperson to this lead';
  const buttonLabel = editVariant ? 'Update' : 'Assign';

  // Assign user api query
  const { mutateAsync: assign, isLoading: isUpdatingAssignee } = partnerApi('assignLeadToUser', {
    leadId: lead?.leadId,
    auth0UserId: selectedPerson?.auth0UserId,
  });

  // Disable the button, assign the user and close the modal
  const assignUser = useCallback(() => {
    if (selectedPerson) {
      assign().then(() => onClose());
    }
  }, [lead, selectedPerson, onClose]);

  // Configure the autocomplete component
  const autocompleteAdornment = (
    <InputAdornment position="start">
      <Search />
    </InputAdornment>
  );

  const allUsers = assignableUsers?.users ?? [];
  const selectOptions = [
    {
      auth0UserId: 'unassigned',
      fullName: 'Unassigned',
    },
    ...allUsers,
  ];

  // Configure the autocomplete component
  const autocomplete = (
    <Autocomplete
      size="small"
      loading={isLoading}
      options={selectOptions ?? []}
      onChange={(e, v) => setSelectedPerson(v)}
      sx={{ backgroundColor: '#ffffff', height: 42 }}
      componentsProps={{ paper: { sx: { borderRadius: 0.2 } } }}
      ListboxProps={{ style: { height: 168 } }}
      getOptionLabel={(option: any) => option.fullName ?? option}
      renderInput={(params) => (
        <TextField
          data-test-id="assign-user-input"
          variant="outlined"
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: autocompleteAdornment,
            sx: { borderRadius: 0.2 },
          }}
          placeholder="Select salesperson"
        />
      )}
    />
  );

  return (
    <div>
      <Modal
        data-test-id="assign-lead-modal"
        aria-labelledby="assign-lead-modal-title"
        aria-describedby="assign-lead-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 250,
        }}
      >
        <Fade in={isOpen}>
          <Box className={classes.modal} data-test-id="assign-lead-modal">
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography id="assign-lead-modal-title" variant="h6" component="h2" sx={{ fontSize: '27px', color: '#191919', lineHeight: '37px' }}>
                  {modalTitle}
                </Typography>
                <Typography id="assign-lead-modal-description" sx={{ mt: 0, fontSize: '14px', color: '#7A7A7A' }}>
                  {modalDescription}
                </Typography>
              </Box>
              <Box>
                <IconButton className={classes.closeButton} onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ mt: 2, flexGrow: '1' }}>{autocomplete}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button data-test-id="assign-modal-update-button" className={classes.updateButton} onClick={assignUser} disabled={isUpdatingAssignee}>
                {buttonLabel}
              </Button>
              {showSkip && (
                <Link to={leadDetailsPath} className={classes.skipLink}>
                  Skip for now, take me to lead details page
                </Link>
              )}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
