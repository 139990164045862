import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled, createTheme } from '@mui/material/styles';
import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';
import { SessionContext } from '../../contexts/SessionContext';
import LeadSelector from '../Leads/Lead/AddLeadModal';
import { ThemeProvider } from '@mui/styles';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const MainLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '25px',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '240px',
  },
}));

const MainLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const MainLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const MainLayout: FC<MainLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const [displayAddModal, setDisplayAddModal] = useState<boolean>(false);

  const theme = createTheme();

  return (
    <MainLayoutRoot>
      <SessionContext.Provider
        value={{
          isSidebarMobileOpen,
          setIsSidebarMobileOpen,
          setDisplayAddModal,
        }}
      >
        {displayAddModal && <LeadSelector />}
        <ThemeProvider theme={theme}>
          <MainNavbar />
          <MainSidebar />
        </ThemeProvider>
        <MainLayoutWrapper>
          <MainLayoutContainer>
            <MainLayoutContent>
              <Outlet />
            </MainLayoutContent>
          </MainLayoutContainer>
        </MainLayoutWrapper>
      </SessionContext.Provider>
    </MainLayoutRoot>
  );
};

export default MainLayout;
