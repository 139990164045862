import { FC, ReactElement, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Drawer, FormControlLabel, Skeleton, Stack, Toolbar } from '@mui/material';
import partnerApi from 'src/api';
import useStyles from './SalespeopleFilter.style';
import closeGreenIcon from '../../assets/img/closeGreen.svg';

interface SalespeopleFilterInterface {
  isOpen: boolean;
  onClose?: () => void;
  onApply: (people: string[]) => void;
  selected: string[];
}

const SalespeopleFilter: FC<SalespeopleFilterInterface> = ({ isOpen, onClose, onApply, selected }): ReactElement => {
  const classes = useStyles();
  const [filtered, setFiltered] = useState<Array<string>>([]);

  // Set the active filtered salespeople to the passed-in selected ones.
  useEffect(() => {
    setFiltered(selected);
  }, [selected, isOpen]);

  const { data: assignableUsers, isLoading } = partnerApi('getAssignableUsers', {}, isOpen);

  const togglePerson = (id: string): void => {
    if (filtered.includes(id)) {
      setFiltered(filtered.filter((filteredId) => filteredId !== id));
    } else {
      setFiltered([...filtered, id]);
    }
  };

  const salespeopleFilters =
    assignableUsers?.users?.map((salesperson) => (
      <div key={salesperson.auth0UserId}>
        <FormControlLabel
          label={salesperson.fullName}
          control={<Checkbox checked={filtered.includes(salesperson.auth0UserId)} onChange={() => togglePerson(salesperson.auth0UserId)} />}
        />
      </div>
    )) ?? [];

  // Display a loading skeleton for slower connections as feedback something is happening while fetching assignable users.
  const loadingSkeleton = [...Array(5)].map((v, i) => (
    <Stack direction="row" spacing={2} key={i}>
      <Skeleton width={25} height={35} sx={{ borderRadius: '6px' }} />
      <Skeleton width="100%" />
    </Stack>
  ));

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Box sx={{ flexGrow: 1 }}>Filter leads</Box>
        <div>
          <button className={classes.closeButton} onClick={onClose}>
            <img src={closeGreenIcon} />
          </button>
        </div>
      </Toolbar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          padding: '32px',
          paddingTop: '20px',
          paddingBottom: '10px',
          overflow: 'scroll',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div className={classes.bySalesperson}>By salesperson</div>
          <button className={classes.clearAll} onClick={() => setFiltered([])}>
            Clear all
          </button>
        </Box>
        <Box sx={{ overflow: 'scroll' }}>
          {isLoading && loadingSkeleton}
          {!isLoading && (
            <>
              <div>
                <FormControlLabel
                  label="Not assigned"
                  control={<Checkbox checked={filtered.includes('unassigned')} onChange={() => togglePerson('unassigned')} />}
                />
              </div>
              {salespeopleFilters}
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ padding: '32px', borderTop: '1px solid #F5F5F8' }}>
        <Button color="primary" className={classes.applyFilter} onClick={() => onApply(filtered)}>
          Apply filter
        </Button>
      </Box>
    </Drawer>
  );
};

export default SalespeopleFilter;
