import { FC, ReactElement, useState } from 'react';
import Minus from 'src/template/material-kit-pro/icons/Minus';
import Plus from 'src/template/material-kit-pro/icons/Plus';
import informationCircle from '../../assets/img/informationCircle.svg';
import useStyles from './PipelineStatusSummaryButton.style';

export interface PipelineStatusSummaryButtonInterface {
  onClick: () => void;
}

const PipelineStatusSummaryButton: FC<PipelineStatusSummaryButtonInterface> = ({ onClick }): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const classes = useStyles({ open: isOpen });

  return (
    <div className={classes.root}>
      <div className={classes.closer} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <Minus /> : <Plus />}
      </div>
      <div className={classes.icon} onClick={onClick}>
        <img src={informationCircle} />
      </div>
      <div className={classes.label} onClick={onClick}>
        See status summary
      </div>
    </div>
  );
};

export default PipelineStatusSummaryButton;
