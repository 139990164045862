import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import TelegramIcon from '@mui/icons-material/Telegram';

const useStyles = makeStyles(() => ({
  box: {
    width: '50%',
    border: '2px solid #E5E5E5',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    padding: '30px',
    margin: '10px',
    cursor: 'pointer',
  },
  selected: {
    border: '2px solid #A1E2C1 !important',
  },
  topBar: {
    display: 'flex',
  },
  radio: {
    marginLeft: 'auto',
  },
  titleText: {
    width: '100%',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '25px',
    lineHeight: '24px',
    color: '#262627',
    paddingBottom: '15px',
  },
  bodyText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '19px',
    lineHeight: '24px',
    color: '#262627',
  },
  titleCloseIcon: {
    padding: '0px',
  },
  icon: {
    color: '#A1E2C1',
    fontSize: '30px',
  },
}));

interface SelectionBoxInterface {
  heading: string;
  body: string;
  type: 'guided' | 'selfServe';
  selected: boolean;
  setSelected: (boolean) => void;
  setDeselected: (boolean) => void;
}

export const SelectionBox: React.FC<SelectionBoxInterface> = ({ heading, body, type, selected, setSelected, setDeselected }) => {
  const classes = useStyles(selected);

  return (
    <Box
      sx={{ alignSelf: 'center' }}
      className={`${classes.box} ${selected ? classes.selected : ''}`}
      onClick={() => {
        setSelected(true);
        setDeselected(false);
      }}
    >
      <div className={classes.topBar}>
        {type === 'guided' && <DriveFileRenameOutlineIcon className={classes.icon} />}
        {type === 'selfServe' && <TelegramIcon className={classes.icon} />}
        <input
          type="radio"
          id={type}
          name={type}
          value={type}
          data-test-id={`input-${type}`}
          className={classes.radio}
          checked={selected}
          onChange={() => {
            setSelected(true);
            setDeselected(false);
          }}
        />
      </div>
      <Typography className={classes.titleText} variant="h6">
        {heading}
      </Typography>
      <Typography className={classes.bodyText}>{body}</Typography>
    </Box>
  );
};
