import { ReactElement } from 'react';
import { Box, Fade, Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import useStyles from './PipelineStatusSummary.style';
import PipelineStatusSummaryButton from './PipelineStatusSummaryButton';
import closeGreen from '../../assets/img/closeGreen.svg';

interface StatusInterface {
  label: string;
  description: string;
}

interface SummaryInterface {
  label: string;
  colour: string;
  statuses: StatusInterface[];
}

interface PipelineStatusSummaryInterface {
  isOpen: boolean;
  onClose: () => void;
}

const PipelineStatusSummary = ({ isOpen = false, onClose }: PipelineStatusSummaryInterface): ReactElement => {
  const classes = useStyles();

  const summaries: SummaryInterface[] = [
    {
      label: 'Pre-application',
      colour: '#3496AA',
      statuses: [
        {
          label: 'Received',
          description: 'Driva has received a lead, but doesn’t have sufficient information to present loan options for that particular customer.',
        },
        {
          label: 'Quoted',
          description: 'Driva has received a lead and has provided loan options to the customer.',
        },
        {
          label: 'Awaiting documents',
          description: 'The customer has finished providing supporting information but needs to provide further documents to proceed.',
        },
      ],
    },
    {
      label: 'With Driva / Lender',
      colour: '#FFF49C',
      statuses: [
        {
          label: 'Under review',
          description: 'The customer has provided enough information and documentation for Driva to assess the loan application.',
        },
        {
          label: 'Lodged',
          description: 'The customer’s application has been lodged with a lender who is assessing eligibility.',
        },
      ],
    },
    {
      label: 'Approved',
      colour: '#A1E2C1',
      statuses: [
        {
          label: 'Conditionally approved',
          description: 'Customer’s application has been approved by the lender. Certain credit conditions must be met prior to full approval.',
        },
        {
          label: 'Approved',
          description:
            'Customer’s application has been fully approved and all outstanding credit conditions have been satisfied. All that is required for settlement is the sales invoice, insurance certificate and signed loan documents.',
        },
      ],
    },
    {
      label: 'Settled',
      colour: '#A1E2C1',
      statuses: [
        {
          label: 'Settled',
          description: 'Funds have been transferred.',
        },
      ],
    },
    {
      label: 'Declined or withdrawn',
      colour: '#FFC3C3',
      statuses: [
        {
          label: 'Declined',
          description: 'The customer is not eligible for finance and is therefore unable to proceed.',
        },
        {
          label: 'Withdrawn',
          description: 'The customer has indicated that they no longer wish to proceed with finance.',
        },
        {
          label: 'Closed',
          description: 'The customer’s application has been closed.',
        },
      ],
    },
  ];

  const summarySections = summaries.map((summary: SummaryInterface) => {
    const statuses = summary.statuses.map((status: StatusInterface) => (
      <p key={status.label} className={classes.status}>
        <strong>{status.label}</strong>
        <span> - </span>
        <span>{status.description}</span>
      </p>
    ));

    return (
      <div key={summary.label} className={classes.section}>
        <div className={classes.divider} style={{ backgroundColor: summary.colour }}></div>
        <div className={classes.sectionLabel}>{summary.label?.toUpperCase()}</div>
        <div>{statuses}</div>
      </div>
    );
  });

  return (
    <Modal
      data-test-id="status-summary-modal"
      aria-labelledby="status-summary-modal-title"
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
    >
      <Fade in={isOpen}>
        <Box className={classes.root}>
          <Box className={classes.header}>
            <span>Status information</span>
            <img src={closeGreen} onClick={onClose} />
          </Box>
          <Box className={classes.content}>{summarySections}</Box>
        </Box>
      </Fade>
    </Modal>
  );
};

PipelineStatusSummary.Button = PipelineStatusSummaryButton;

export default PipelineStatusSummary;
