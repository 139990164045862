import { useEffect } from 'react';
import type { FC } from 'react';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import LoginAuth0 from '../../components/authentication/login';
import useAuth from '../../hooks/useAuth';
import gtm from '../../template/material-kit-pro/lib/gtm';
import logo from '../../assets/img/logo2.svg';
import { Helmet } from 'react-helmet-async';

const Login: FC = () => {
  const { platform } = useAuth() as any;

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login - Driva Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm" sx={{ py: '80px' }}>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Log in
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Log in to the Driva Dashboard
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 70,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto',
                    },
                  }}
                >
                  <img alt="Driva" src={logo} />
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                {platform === 'Auth0' && <LoginAuth0 />}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
