export enum APPLICATION_STATUS {
  RECEIVED = 100,
  QUOTED = 200,
  STARTED = 300,
  AWAITING_DOCUMENTS = 400,
  UNDER_REVIEW = 500,
  LODGED = 600,
  CONDITIONALLY_APPROVED = 700,
  APPROVED = 800,
  SETTLED = 900,
  WITHDRAWN = 930,
  DECLINED = 950,
  CLOSED = 970
}

export const APPLICATION_STATUS_MAP = {
  [APPLICATION_STATUS.RECEIVED]: 'Received',
  [APPLICATION_STATUS.QUOTED]: 'Quoted',
  [APPLICATION_STATUS.STARTED]: 'Started',
  [APPLICATION_STATUS.AWAITING_DOCUMENTS]: 'Awaiting documents',
  [APPLICATION_STATUS.UNDER_REVIEW]: 'Under review',
  [APPLICATION_STATUS.LODGED]: 'Submitted',
  [APPLICATION_STATUS.CONDITIONALLY_APPROVED]: 'Conditionally approved',
  [APPLICATION_STATUS.APPROVED]: 'Approved',
  [APPLICATION_STATUS.SETTLED]: 'Settled',
  [APPLICATION_STATUS.WITHDRAWN]: 'Withdrawn',
  [APPLICATION_STATUS.DECLINED]: 'Declined',
  [APPLICATION_STATUS.CLOSED]: 'Closed'
};

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};
