import { useEffect, useContext } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Drawer, Button, Divider, List, ListItem, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Close from '@mui/icons-material/Close';
import PlusIcon from '../../template/material-kit-pro/icons/Plus';
import NavSection from '../../template/material-kit-pro/components/NavSection';
import useAuth from '../../hooks/useAuth';
import toast from 'react-hot-toast';
import { SessionContext } from '../../contexts/SessionContext';
import logo from '../../assets/img/logo2.svg';
import PartnerSwitcher from 'src/components/PartnerSwitcher/PartnerSwitcher';
import { PERMISSIONS } from '../Permission/constants';

const useStyles = makeStyles((theme: Theme) => {
  return {
    mainBox: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: '#1C242A',
    },
    menuBox: {
      padding: '15px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    logoBox: {
      minHeight: 64,
      justifyContent: 'space-between',
      p: 2,

      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
      },
    },
    logoFiller: {
      width: '40px',
    },
    closeButton: {
      color: 'grey',
    },
    divider: {
      borderColor: '#fff',
    },
    logoutButton: {
      color: '#fff',
      fontWeight: 'initial',
      justifyContent: 'flex-start',
      padding: '10px',
      textAlign: 'left',
      textTransform: 'none',
      width: '100%',
    },
    partnerBox: {
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      padding: '10px',

      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    userBox: {},
    userName: {
      color: '#3E3F42',
      lineHeight: '0.9',
      margin: '8px',
      fontSize: '13px',
      fontWeight: 'bold',
    },
    partnerName: {
      color: '#3E3F42',
      lineHeight: '0.9',
      margin: '8px',
      fontSize: '12px',
    },
  };
});

const hasAddLeadPermission = (permissions: string[]) => {
  return permissions.find((permission) => permission === PERMISSIONS.LEAD_CREATE);
};

const MainSidebar: FC = () => {
  const { isSidebarMobileOpen, setIsSidebarMobileOpen, setDisplayAddModal } = useContext(SessionContext);
  const location = useLocation();
  const classes = useStyles();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  let links = [
    {
      title: 'Dashboard',
      path: '/',
      permission: PERMISSIONS.LEADS_GET_ALL,
    },
    {
      title: 'Leads',
      path: '/leads',
      permission: PERMISSIONS.LEADS_GET_ALL,
    },
    {
      title: 'My Leads',
      path: '/my-leads',
      permission: PERMISSIONS.LEADS_GET_ALL,
    },
    {
      title: 'Group Leads',
      path: '/group-leads',
      permission: PERMISSIONS.LEADS_GET_ALL,
    },
  ];

  const {
    logout,
    user: { permissions },
  } = useAuth();

  useEffect(() => {
    if (isSidebarMobileOpen) {
      setIsSidebarMobileOpen(false);
    }
  }, [location.pathname]);

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  const onMobileClose = () => {
    setIsSidebarMobileOpen(false);
  };

  const onAddLeadClick = () => {
    setIsSidebarMobileOpen(false);
    setDisplayAddModal(true);
  };

  const getPermittedLinks = () => {
    if (permissions.length === 0) {
      return [];
    }

    return links.filter((link) => permissions.indexOf(link.permission) > -1);
  };

  const permittedLinks = getPermittedLinks();
  const sections = [
    {
      title: '',
      items: permittedLinks,
    },
  ];

  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const content = (
    <Box className={classes.mainBox}>
      <Box className={classes.logoBox}>
        <Box className={classes.logoFiller} />
        <RouterLink to="/">
          <img alt="Logo" src={logo} />
        </RouterLink>
        <Box className={classes.logoFiller}>
          <Button startIcon={<Close fontSize="small" />} variant="text" onClick={onMobileClose} className={classes.closeButton} />
        </Box>
      </Box>
      <Box className={classes.partnerBox}>
        <PartnerSwitcher variant="sidebar" />
      </Box>
      <Box className={classes.menuBox}>
        {hasAddLeadPermission(permissions) && (
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
              <Button
                color="primary"
                endIcon={<PlusIcon fontSize="small" />}
                sx={{ m: 1, width: '200px' }}
                variant="contained"
                onClick={onAddLeadClick}
                data-test-id="btn-add-lead"
              >
                Add Lead
              </Button>
            </Box>
          </Grid>
        )}

        {sections.map((section) => (
          <NavSection
            key={section.title}
            pathname={location.pathname}
            onMobileClose={onMobileClose}
            sx={{
              '& + &': {
                mt: 3,
              },
              a: {
                mx: 2,
                p: 1,
              },
              'a > div': {
                borderBottom: '1px solid',
                borderColor: 'transparent',
                pb: 1,
              },
              'a:not(.active)': {
                color: '#fff',
              },
              'a.active div': {
                borderBottomColor: 'primary.main',
              },
            }}
            {...section}
          />
        ))}
      </Box>
      <Divider className={classes.divider} />
      <Box sx={{ p: 2, py: 1 }}>
        <List>
          <ListItem
            disableGutters
            sx={{
              display: 'block',
              py: 0,
            }}
          >
            <Button startIcon={<ExitToAppIcon />} onClick={handleLogout} className={classes.logoutButton} variant="text">
              <Box>Logout</Box>
            </Button>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            borderRight: 0,
            top: '64px !Important',
            width: 240,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={isSidebarMobileOpen}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: isMobileView ? '100%' : 240,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default MainSidebar;
