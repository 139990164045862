import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import PermissionRequired from './PermissionRequired';
import { PERMISSIONS } from './constants';

const RoutePermissionMap = {
  Dashboard: PERMISSIONS.DASHBOARD,
  Leads: PERMISSIONS.LEADS_GET_ALL,
  Lead: PERMISSIONS.LEAD_GET,
};
interface PermissionProviderProps {
  routeName: string;
  children: ReactNode;
}

const PermissionProvider: FC<PermissionProviderProps> = (props) => {
  const { children, routeName } = props;
  const auth = useAuth();
  const {
    user: { permissions },
  } = auth;

  const routePermission = RoutePermissionMap[routeName];

  if (permissions.indexOf(routePermission) === -1) {
    return <PermissionRequired />;
  }

  return <>{children}</>;
};

PermissionProvider.propTypes = {
  routeName: PropTypes.string,
  children: PropTypes.node,
};

export default PermissionProvider;
