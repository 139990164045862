import { createContext, useEffect, useReducer, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { auth0Config } from '../../../config';
import type { User } from '../types/user';
import axios from '../../../lib/axios';

let auth0Client: Auth0Client | null = null;

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  isLoading: boolean;
}
export interface AuthContextValue extends State {
  platform: 'Auth0';
  loginWithPopup: (options?: any) => Promise<void>;
  logout: () => void;
}
interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isLoading: true,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      isLoading: false,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      isLoading: false,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    isLoading: false,
  }),
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

const getUserObject = async (userId: string): Promise<any> => {
  const response = await axios(`${process.env.REACT_APP_SERVER_BASE_URL}/api/auth/${userId}`);

  return response.data.result;
};

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin,
          ...auth0Config,
          useRefreshTokens: true,
          cacheLocation: 'localstorage',
          audience: process.env.REACT_APP_SERVER_BASE_URL,
        });
        const jwtToken = await auth0Client.getTokenSilently();
        axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          const { sub } = user;
          const userObj = await getUserObject(sub);
          // Here you should extract the complete user profile to make it
          // available in your entire app.
          // The auth state only provides basic information.

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: {
                id: user.sub,
                avatar: user.picture,
                email: user.email,
                name: userObj.user_metadata.full_name,
                permissions: userObj.permissions,
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const loginWithPopup = async (): Promise<void> => {
    await auth0Client.loginWithPopup();
    const jwtToken = await auth0Client.getTokenSilently();
    axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      const { sub } = user;
      const userObj = await getUserObject(sub);
      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: userObj.user_metadata.full_name,
            permissions: userObj.permissions,
          },
        },
      });
    }
  };

  const logout = async (): Promise<void> => {
    await auth0Client.logout({
      federated: true,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: window.location.origin,
    });

    dispatch({
      type: 'LOGOUT',
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Auth0',
        loginWithPopup,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
