import { makeStyles } from '@mui/styles';

interface StyleProps {
  showSkip: boolean;
}

const useStyles = makeStyles(() => ({
  modal: ({ showSkip }: StyleProps) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 431,
    height: showSkip ? 466 : 418,
    backgroundColor: '#FBFBFD',
    border: '1px solid #E2E2E2',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    padding: 32,
    display: 'flex',
    flexDirection: 'column',

    '@media (max-width: 500px)': {
      width: '100%',
      borderRadius: 0
    },

    '@media (max-height: 470px)': {
      height: '100%',
      borderRadius: 0
    }
  }),
  closeButton: {
    padding: 0,
    marginTop: -30,
    marginRight: -15
  },
  updateButton: {
    display: 'block',
    width: '100%',
    backgroundColor: '#FFF39C',
    borderRadius: '25px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    color: '#1C242A',
    padding: 12,
    '&:hover': {
      backgroundColor: '#e3d889'
    }
  },
  skipLink: {
    color: '#3E3F42',
    textAlign: 'center',
    marginTop: 24
  }
}));

export default useStyles;
