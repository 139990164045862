import React from 'react';
import { useNavigate } from 'react-router';
import MicroFrontend from '../MicroFrontend/MicroFrontend';

const ReferrerLedJourney = () => {
  const navigate = useNavigate();

  // This is hard-coded to simplify the example, but an env or config file could be used just as easy.
  // const HOST = process.env.REACT_APP_MFE_EXAMPLE_HOST
  const HOST = process.env.REACT_APP_RFJ_MF;

  return <MicroFrontend navigate={navigate} host={HOST} name="Rlj" basename="/referrer-led-journey" />;
};

export default ReferrerLedJourney;
