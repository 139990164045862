import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import './i18n';
import RTL from './template/material-kit-pro/components/RTL';
import { gtmConfig } from './config';
import useScrollReset from './template/material-kit-pro/hooks/useScrollReset';
import useSettings from './template/material-kit-pro/hooks/useSettings';
import gtm from './template/material-kit-pro/lib/gtm';
import { routes } from './routes';
import { createCustomTheme } from './template/material-kit-pro/theme';
import useAuth from './template/material-kit-pro/hooks/useAuth';
import ErrorFallback from './components/ErrorFallback';
import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init({
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: process.env.REACT_APP_DD_SERVICE || '',
  env: process.env.REACT_APP_DD_ENV || 'local',
  version: process.env.REACT_APP_DD_VERSION || '0.0.0',
  forwardErrorsToLogs: true,
  forwardConsoleLogs: 'all',
  sampleRate: 100,
});

const Fullstory = (window as any).FS;

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();

  useScrollReset();

  const { user } = useAuth();
  if (user) {
    Fullstory.identify(user?.id, {
      displayName: user?.name,
      email: user?.email,
    });
  }

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'LIGHT',
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <CssBaseline />
          <Toaster position="top-center" />
          {content}
        </ErrorBoundary>
      </RTL>
    </ThemeProvider>
  );
};

const clientSideID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID;
export default withLDProvider({
  clientSideID: clientSideID ? clientSideID : '',
})(App);
