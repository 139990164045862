import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  salespersonFilterButton: {
    backgroundColor: '#43414F',
    color: '#fff',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '22px',
    height: '50px',
    padding: '15px 10px',

    '& img': {
      marginLeft: '6px'
    },

    '&:hover': {
      backgroundColor: '#43414F'
    },

    '@media (max-width: 723px)': {
      marginTop: '5px'
    }
  },
  salespeopleFilterCount: {
    height: '20px',
    width: '20px',
    color: '#43414F',
    backgroundColor: '#A1E2C1',
    borderRadius: '100%',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '12px',
    lineHeight: '22px',
    marginLeft: '8px',
    textAlign: 'center'
  }
}));

export default useStyles;
