import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 20000
  },
  toolbar: {
    width: '352px',
    color: '#fff',
    backgroundColor: '#1C242A',
    display: 'flex',
    alignContent: 'center',
    fontSize: '24px',
    fontFamily: 'Avenir',
    fontStye: 'normal',
    fontWeight: '800',
    lineHeight: '33px',

    [theme.breakpoints.down('sm')]: {
      width: '320px'
    }
  },
  closeButton: {
    background: 'transparent',
    border: '0',
    cursor: 'pointer'
  },
  bySalesperson: {
    flexGrow: 1,
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '27px'
  },
  clearAll: {
    background: 'transparent',
    border: 0,
    textDecoration: 'underline',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'none'
    }
  },
  applyFilter: {
    width: '100%',
    backgroundColor: '#A1E2C1',
    color: '#1C242A',
    padding: '14px',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    borderRadius: 25,

    '&:hover': {
      backgroundColor: '#86c6a5'
    }
  }
}));

export default useStyles;
