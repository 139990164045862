import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';

import AuthGuard from '../components/AuthGuard';
import MainLayout from '../views/Main/MainLayout';
import GuestGuard from '../template/material-kit-pro/components/GuestGuard';
import LoadingScreen from '../template/material-kit-pro/components/LoadingScreen';
import MyLeads from 'src/views/Leads/MyLeads';
import DealershipLeads from 'src/views/DealershipLeads/DealershipLeads';
import ReferrerLedJourney from 'src/components/ReferrerLedJourney/ReferrerLedJourney';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages
const Login = Loadable(lazy(() => import('../template/material-kit-pro/pages/authentication/Login')));

// Dashboard pages
const Dashboard = Loadable(lazy(() => import('../views/Dashboard/Dashboard')));
const LeadList = Loadable(lazy(() => import('../views/Leads/Leads')));
const Lead = Loadable(lazy(() => import('../views/Leads/Lead')));

// Error pages
const NotFound = Loadable(lazy(() => import('../template/material-kit-pro/pages/NotFound')));
const ServerError = Loadable(lazy(() => import('../template/material-kit-pro/pages/ServerError')));

const Routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
    ],
  },
  {
    path: 'referrer-led-journey/*',
    element: (
      <AuthGuard>
        <ReferrerLedJourney />
      </AuthGuard>
    ),
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'leads',
        element: <LeadList />,
      },
      {
        path: 'my-leads',
        element: <MyLeads />,
      },
      {
        path: 'group-leads',
        element: <DealershipLeads />,
      },
      {
        path: 'lead/:leadId',
        element: <Lead />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export const routes = Routes;
