import { useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';

interface MicroFrontendInterface {
  name: string;
  basename: string;
  host: string | undefined;
  navigate: any;
}

const MicroFrontend = ({ name, host, navigate, basename }: MicroFrontendInterface) => {
  const { user } = useAuth();
  const [fetched, setFetched] = useState(false);
  const scriptId = `micro-frontend-script-${name}`;
  const containerId = `${name}-container`;

  const renderMicroFrontend = () => {
    const state = {
      user,
      navigate,
    };

    window[`render${name}`](basename, containerId, state);
  };

  useEffect(() => {
    if (document.getElementById(scriptId)) {
      renderMicroFrontend();
      return;
    }

    if (!document.getElementById(scriptId) && !fetched) {
      setFetched(true);

      fetch(`${host}/manifest.json?${Date.now()}`)
        .then((res) => res.json())
        .then((manifest) => {
          manifest['index.html'].css.map((file) => {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = `${host}/${file}`;
            link.media = 'screen';
            document.head.appendChild(link);
          });

          manifest['index.html'].imports?.map((importName) => {
            const importScript = document.createElement('script');
            importScript.crossOrigin = '';
            importScript.type = 'module';
            importScript.src = `${host}/${manifest[importName].file}`;
            document.head.appendChild(importScript);
          });

          const script = document.createElement('script');
          script.id = scriptId;
          script.crossOrigin = '';
          script.type = 'module';
          script.src = `${host}/${manifest['index.html'].file}`;
          script.onload = () => {
            renderMicroFrontend();
          };

          document.head.appendChild(script);
        });
    }

    return () => {
      window[`unmount${name}`] && window[`unmount${name}`](containerId);
    };
  });

  return <main id={containerId} />;
};

MicroFrontend.defaultProps = {
  document,
  window,
};

export default MicroFrontend;
