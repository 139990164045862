import { DateTime } from 'luxon';

export const numberWithCommas = (x?: string) => {
  if (x === undefined || x === null) {
    x = '0';
  }
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertToCurrency = (amount?: string, truncate: boolean = false) => {
  // Remove the `.00` by converting to number and truncating
  if (truncate) {
    amount = amount ? Math.trunc(+amount as number).toString() : amount;
  }

  return `$ ${numberWithCommas(amount?.toString())}`;
};

export const setFocusToErrorField = (errorArray: Record<string, string>): void => {
  if (Object.keys(errorArray).length > 0) {
    const errorElement: HTMLElement = document.querySelectorAll(`[name$='${Object.keys(errorArray)[0]}']`)[0] as HTMLElement;
    errorElement && errorElement.scrollIntoView();
    setTimeout(() => {
      window.scrollBy(0, -180);
    }, 10);
    errorElement && errorElement.focus();
  }
};

export const hasPermission = (permissions: string[], permission: string) => {
  return permissions.indexOf(permission) > -1;
};

export const retrievePersistentDateFilter = (filterName: string) => {
  const monthStart = DateTime.local().startOf('month');
  const todayEnd = DateTime.local().endOf('day');

  const today = DateTime.local().toISODate();

  let from = localStorage.getItem(`${filterName}.from`);
  let to = localStorage.getItem(`${filterName}.to`);
  const lastFilterSetDate = localStorage.getItem('lastFilterSetDate');

  if (!from || !to) {
    localStorage.setItem(`${filterName}.from`, monthStart.toFormat('yyyy-MM-dd'));
    localStorage.setItem(`${filterName}.to`, todayEnd.toFormat('yyyy-MM-dd'));

    localStorage.setItem('lastFilterSetDate', today);

    from = localStorage.getItem(`${filterName}.from`);
    to = localStorage.getItem(`${filterName}.to`);
  }

  // Expire filters from previous days (local time)
  if (!lastFilterSetDate || today.toString() !== lastFilterSetDate) {
    localStorage.setItem(`${filterName}.from`, monthStart.toFormat('yyyy-MM-dd'));
    localStorage.setItem(`${filterName}.to`, todayEnd.toFormat('yyyy-MM-dd'));

    localStorage.setItem('lastFilterSetDate', today);
  }

  return { from, to };
};
