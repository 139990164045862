import createSvgIcon from '@mui/material/utils/createSvgIcon';

const Minus = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 12H6" />
  </svg>,
  'Minus'
);

export default Minus;
