export const PERMISSIONS = {
  DASHBOARD: 'read:dashboard',
  ANNOUNCEMENT_READ: 'read:announcement',
  REVENUE_READ: 'read:revenue',
  LEADS_GET_ALL: 'read:leads',
  LEAD_CREATE: 'create:lead',
  LEAD_GET: 'read:lead-details',
  LEAD_GET_TIMELINE: 'read:lead-timeline',
  LEAD_UPDATE_NOTES: 'update:lead-notes',
  LEAD_GET_FILES: 'read:lead-files',
  LEAD_VIEW_QUOTE: 'read:lead-view-quote',
  LEAD_ASSIGNMENT: 'create:lead-assignment',
};
