interface IApiList {
  [key: string]: {
    url: string;
    method: 'get' | 'GET' | 'post' | 'delete' | 'patch' | 'put';
    headers?: string[];
    refreshFunc?: string;
    enabled?: boolean;
  };
}

// DEVNOTE: get - useQuery, GET - useMutation
export const apiList: IApiList = {
  getLeads: {
    url: '/api/leads',
    method: 'get'
  },
  getLead: {
    url: '/api/lead/:leadId',
    method: 'get'
  },
  addLead: {
    url: '/api/lead',
    method: 'post'
  },
  getUser: {
    url: '/api/auth',
    method: 'GET'
  },
  queryDashboardChart: {
    url: '/api/dashboard',
    method: 'get'
  },
  getDashboardPartnerRevenue: {
    url: '/api/dashboard/partner-revenue',
    method: 'get'
  },
  getDashboardNotifications: {
    url: '/api/dashboard/notifications',
    method: 'get'
  },
  getDashboardLatestLeads: {
    url: '/api/dashboard/latest-leads',
    method: 'get'
  },
  getDashboardLatestUpdate: {
    url: '/api/dashboard/latest-update',
    method: 'get'
  },
  getNotes: {
    url: '/api/lead/:leadId/notes',
    method: 'get'
  },
  addNote: {
    url: '/api/lead/:leadId/note',
    method: 'post'
  },
  getS3File: {
    url: '/api/lead/:leadId/s3/file/:fileId',
    method: 'get'
  },
  getFiles: {
    url: '/api/lead/:leadId/files',
    method: 'get'
  },
  uploadFile: {
    url: '/api/lead/:leadId/file',
    method: 'post'
  },
  deleteFile: {
    url: '/api/lead/:leadId/file/:fileId',
    method: 'delete'
  },
  syncFileToSalesforce: {
    url: '/api/lead/:leadId/file/salesforce',
    method: 'post'
  },
  getAssignableUsers: {
    url: '/api/assignment/assignableUsers',
    method: 'get'
  },
  assignLeadToUser: {
    url: '/api/assignment/lead/:leadId/:auth0UserId',
    method: 'post'
  },
  getPartners: {
    url: '/api/auth/partners',
    method: 'get'
  },
  switchPartner: {
    url: '/api/auth/switch-partner',
    method: 'post'
  }
};
