import { Box, Skeleton, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import partnerApi from 'src/api';
import AuthContext from 'src/contexts/Auth0Context';
import useAuth from '../../hooks/useAuth';
import ChevronDown from '../../template/material-kit-pro/icons/ChevronDown';
import ChevronUp from '../../template/material-kit-pro/icons/ChevronUp';
import useStyles from './PartnerSwitcher.styles';

interface PartnerSwitcherInterface {
  variant?: 'header' | 'sidebar';
}

const PartnerSwitcher = ({ variant = 'header' }: PartnerSwitcherInterface) => {
  const {
    user: {
      name,
      email,
      permissions,
      partner: { name: partnerName, logo: partnerLogo },
    },
  } = useAuth();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSwitching, setIsSwitching] = useState<boolean>(false);
  const classes = useStyles({ variant });
  const dropdownRef = useRef<HTMLDivElement>();
  const [searchText, setSearchText] = useState<string>();
  const [filteredPartners, setFilteredPartners] = useState([]);

  // Ensure the user has the switch permission and has a Driva email, sometimes people make non-Driva accounts admins... /shrug
  const canSwitchPartners = permissions.includes('partner:switch') && email?.includes('@driva.com.au');

  const dropdownIcon = isOpen ? <ChevronUp fontSize="small" /> : <ChevronDown fontSize="small" />;
  const toggleSwitcher = () => setIsOpen(!isOpen);

  // Queries/requests
  const { data: partners, isLoading } = partnerApi('getPartners', {}, isOpen);
  const { mutateAsync: switchPartner, error: switchPartnerError } = partnerApi('switchPartner');

  // Update UI to indicate something is happening and send off the request to update the users partnerId.
  const changePartner = (partnerId: string): void => {
    setIsOpen(false);
    setIsSwitching(true);

    switchPartner({ partnerId: partnerId }).then((resp) => {
      if (!switchPartnerError) {
        authContext.updateUser(resp).then(() => {
          setIsSwitching(false);

          // Reload page, "cleaner" than updating all components to hook into the partnerName/ID change.
          navigate(0);
        });
      } else {
        setIsSwitching(true);
      }
    });
  };

  // Close dropdown if a click is detected outside the dropdown.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const partnerOptions = filteredPartners.map((partner) => (
    <li title={partner.name} onClick={() => changePartner(partner.partnerId)} key={partner.partnerId}>
      {partner.name}
    </li>
  ));

  const search = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    const filtered =
      partners?.partners?.filter((partner) => {
        return searchText && searchText.length > 0 ? partner.name.toLowerCase().includes(searchText.toLowerCase()) : true;
      }) ?? [];

    setFilteredPartners(filtered);
  }, [searchText, partners]);

  return (
    <>
      {variant === 'header' && (
        <Box className={classes.partnerLogoBox}>
          <img alt="Logo" height="26px" src={partnerLogo} />
        </Box>
      )}
      <Box className={classes.userBox}>
        <Typography component="div">
          <Box className={classes.userName}>{name}</Box>
          {!canSwitchPartners && <Box className={classes.partnerName}>{partnerName}</Box>}
          {canSwitchPartners && (
            <>
              <div className={classes.partnerSwitcher} onClick={toggleSwitcher}>
                <div>{isSwitching ? 'Switching...' : partnerName}</div>
                <div>{dropdownIcon}</div>
              </div>
              <div className={classes.switcherDropdown} style={{ display: isOpen ? 'block' : 'none' }} ref={dropdownRef}>
                <input type="search" onChange={search} className={classes.searchInput} placeholder="Filter partners..." />
                <ul>
                  {isLoading && <Skeleton />}
                  {!isLoading && partnerOptions}
                </ul>
              </div>
            </>
          )}
        </Typography>
      </Box>
    </>
  );
};

export default PartnerSwitcher;
