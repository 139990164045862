import React, { useState } from 'react';
import { Box, Button, createTheme, Stack } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { ThemeProvider } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router';

import { APPLICATION_STATUS, APPLICATION_STATUS_MAP } from 'src/constants';
import AssignLeadModal from 'src/components/AssignLeadModal/AssignLeadModal';
import SalespeopleFilter from 'src/components/SalespeopleFilter/SalespeopleFilter';
import { Lead } from '../../Leads/types';
import PipelineColumn from '../PipelineColumn/PipelineColumn';
import useStyles from './Pipeline.style';
import filterIcon from '../../../assets/img/filter.svg';
import useAuth from '../../../hooks/useAuth';
import { PERMISSIONS } from '../../../views/Permission/constants';

// Move to config file?
LicenseInfo.setLicenseKey('4ecba3e0948dec1cfa98faf11d918afdT1JERVI6MjkxNDIsRVhQSVJZPTE2NjI3NzQ0MjcwMDAsS0VZVkVSU0lPTj0x');

interface PipelineInterface {
  displayRange: DateRange<Date>;
  setDisplayRange: (newRange: DateRange<Date>) => void;
  leads: Lead[];
  refetchLeads: () => {};
  withSalespersonFilter?: boolean;
  assigneeId?: string;
}

const Pipeline = ({ leads, displayRange, setDisplayRange, refetchLeads, withSalespersonFilter = true, assigneeId }: PipelineInterface) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [activeLead, setActiveLead] = useState(null);
  const [isSalespersonFilterOpen, setIsSalespersonFilterOpen] = useState<boolean>(false);
  const [includeClosed, setIncludeClosed] = useState<boolean>(false);
  const [salespeople, setSalespeople] = useState<string[]>(JSON.parse(localStorage.getItem('dealershipleads.salespeople')) ?? []);
  const {
    user: { permissions },
  } = useAuth();

  // Statuses for each column
  const preapplicationStatuses = [
    APPLICATION_STATUS_MAP[APPLICATION_STATUS.STARTED],
    APPLICATION_STATUS_MAP[APPLICATION_STATUS.RECEIVED],
    APPLICATION_STATUS_MAP[APPLICATION_STATUS.QUOTED],
    APPLICATION_STATUS_MAP[APPLICATION_STATUS.AWAITING_DOCUMENTS],
  ];

  const withDrivaStatuses = [APPLICATION_STATUS_MAP[APPLICATION_STATUS.UNDER_REVIEW], APPLICATION_STATUS_MAP[APPLICATION_STATUS.LODGED]];

  const approvedStatuses = [APPLICATION_STATUS_MAP[APPLICATION_STATUS.CONDITIONALLY_APPROVED], APPLICATION_STATUS_MAP[APPLICATION_STATUS.APPROVED]];

  const declinedAndWithdrawnStatuses = [
    APPLICATION_STATUS_MAP[APPLICATION_STATUS.DECLINED],
    APPLICATION_STATUS_MAP[APPLICATION_STATUS.WITHDRAWN],
    APPLICATION_STATUS_MAP[APPLICATION_STATUS.CLOSED],
  ];

  // Filter leads by salesperson if any are set.
  let filteredLeads = leads;
  if (salespeople.length > 0 && leads) {
    filteredLeads = leads.filter((lead) => {
      if (salespeople.includes('unassigned')) {
        return salespeople.includes(lead.assignee?.id) || lead.assignee === null;
      } else {
        return salespeople.includes(lead.assignee?.id);
      }
    });
  }

  if (!includeClosed && filteredLeads) {
    filteredLeads = filteredLeads?.filter((lead) => lead.status !== APPLICATION_STATUS_MAP[APPLICATION_STATUS.CLOSED]);
  }

  // Leads for each column
  const preapplicationLeads = filteredLeads?.filter((lead) => preapplicationStatuses.includes(lead.status));
  const withDrivaLeads = filteredLeads?.filter((lead) => withDrivaStatuses.includes(lead.status));
  const approvedLeads = filteredLeads?.filter((lead) => approvedStatuses.includes(lead.status));
  const settledLeads = filteredLeads?.filter((lead) => APPLICATION_STATUS_MAP[APPLICATION_STATUS.SETTLED] === lead.status);
  const declinedAndWithdrawnLeads = filteredLeads?.filter((lead) => declinedAndWithdrawnStatuses.includes(lead.status));

  const handleAssignLead = (lead): void => {
    if (lead.assignee?.id || !permissions?.includes(PERMISSIONS.LEAD_ASSIGNMENT)) {
      navigate(`/lead/${lead.leadId}`, { state: { from: 'pipeline' } });
    } else {
      setActiveLead(lead);
    }
  };

  // On assign modal close, refetch leads
  const closeAssignModal = (): void => {
    setActiveLead(null);
    refetchLeads();
  };

  // Close drawer when applying salesperson filter.
  const onApplySalespersonFilter = (people: string[]) => {
    localStorage.setItem('dealershipleads.salespeople', JSON.stringify(people));
    setSalespeople(people);
    setIsSalespersonFilterOpen(false);
  };

  const theme = createTheme();

  const toggleIncludeClosed = () => {
    setIncludeClosed(!includeClosed);
  };

  return (
    <ThemeProvider theme={theme}>
      <AssignLeadModal lead={activeLead} showSkip editVariant={activeLead?.assignee?.id} isOpen={activeLead !== null} onClose={closeAssignModal} />
      <SalespeopleFilter
        isOpen={isSalespersonFilterOpen}
        onClose={() => setIsSalespersonFilterOpen(false)}
        onApply={onApplySalespersonFilter}
        selected={salespeople}
      />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          pb: 3,
        }}
      >
        <Box sx={{ mr: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Period start"
              endText="Period end"
              inputFormat="dd/MM/yyyy"
              value={displayRange}
              onChange={(newValue) => setDisplayRange(newValue)}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box>
          {withSalespersonFilter && (
            <Button className={classes.salespersonFilterButton} onClick={() => setIsSalespersonFilterOpen(true)} startIcon={<img src={filterIcon} />}>
              <span>Filter by salesperson</span>
              {salespeople.length > 0 && <div className={classes.salespeopleFilterCount}>{salespeople.length}</div>}
            </Button>
          )}
        </Box>
        <Box sx={{ marginLeft: '6px' }}>
          <Button className={classes.salespersonFilterButton} onClick={toggleIncludeClosed}>
            {includeClosed ? <span>Hide Closed</span> : <span>Show Closed</span>}
          </Button>
        </Box>
      </Box>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          flexGrow: '1',
        }}
      >
        <PipelineColumn
          title="Pre-application"
          colour="BLUE"
          showMorePath="/leads?status=Preapplication"
          assigneeId={assigneeId}
          count={preapplicationLeads?.length ?? 0}
          leads={preapplicationLeads ?? []}
          assignLead={handleAssignLead}
        />
        <PipelineColumn
          title="With Driva / Lender"
          colour="YELLOW"
          showMorePath="/leads?status=Lodged"
          assigneeId={assigneeId}
          count={withDrivaLeads?.length ?? 0}
          leads={withDrivaLeads ?? []}
          assignLead={handleAssignLead}
        />
        <PipelineColumn
          title="Approved"
          colour="GREEN"
          showMorePath="/leads?status=Approved"
          assigneeId={assigneeId}
          count={approvedLeads?.length ?? 0}
          leads={approvedLeads ?? []}
          assignLead={handleAssignLead}
        />
        <PipelineColumn
          title="Settled"
          colour="GREEN"
          showMorePath="/leads?status=Settled"
          assigneeId={assigneeId}
          count={settledLeads?.length ?? 0}
          leads={settledLeads ?? []}
          assignLead={handleAssignLead}
        />
        <PipelineColumn
          title="Declined or Withdrawn"
          colour="RED"
          showMorePath="/leads?status=Withdrawn"
          assigneeId={assigneeId}
          count={declinedAndWithdrawnLeads?.length ?? 0}
          leads={declinedAndWithdrawnLeads ?? []}
          assignLead={handleAssignLead}
        />
      </Stack>
    </ThemeProvider>
  );
};

export default Pipeline;
