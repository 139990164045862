import { makeStyles } from '@mui/styles';

interface StyleProps {
  colour: string;
}

const useStyles = makeStyles(({}) => ({
  root: ({ colour }: StyleProps) => ({
    borderRadius: 8,
    borderTop: '10px solid',
    borderTopColor: colour,
    padding: 8,
    backgroundColor: '#F5F5F8',
    minWidth: 207,
    maxWidth: 207,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }),
  title: {
    marginTop: 9,
    marginBottom: 9,
    lineHeight: '28px',
    fontFamily: 'Avenir',
    fontWeight: 800,
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#3E3F42'
  },
  leads: {
    width: '100%',
    marginTop: 12
  },
  lead: {
    backgroundColor: '#fff',
    borderRadius: 4,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    height: 113,
    marginBottom: 16,
    cursor: 'pointer',

    '&:last-child': {
      marginBottom: 0
    }
  },
  leadTitle: {
    padding: '2px 8px',
    width: '100%',
    height: 32,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: '#43414F',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '28px',

    '& > span': {
      position: 'absolute',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '140px'
    }
  },
  leadContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  loanAmount: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    padding: 8
  },
  vehicle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    padding: 8,
    paddingTop: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 180,

    '& > span': {
      marginRight: 4
    }
  },
  status: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    flexDirection: 'row',
    padding: 8,
    paddingTop: 0,

    '& > span': {
      flexGrow: 1
    }
  },
  statusCircle: ({ colour }: StyleProps) => ({
    width: 16,
    height: 16,
    backgroundColor: colour,
    borderRadius: '100%',
    marginRight: 6
  }),
  arrow: {},
  showMoreLeads: {
    color: '#fff',
    backgroundColor: '#43414F',
    height: '60px',
    width: '100%',
    borderRadius: '4px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '28px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textDecoration: 'none',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    padding: 8,

    '& > span:first-child': {
      flexGrow: 1,
      textAlign: 'center'
    }
  }
}));

export default useStyles;
