import Close from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ASSET_TYPE, VEHICLE_STATE } from '@driva-development/driva-types';
import partnerApi from 'src/api';
import { SessionContext } from 'src/contexts/SessionContext';
import { setFocusToErrorField } from 'src/utils/helper';
import { validationSchema } from 'src/views/Leads/validations';

const useStyles = makeStyles(() => ({
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleText: {
    width: '100%',
  },
  titleCloseIcon: {
    padding: '0px',
  },
}));

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  vehiclePrice: 0,
  deposit: 0,
  assetType: '',
  vehicleMake: '',
  vehicleModel: '',
  vehicleYear: '',
  assetCondition: '',
};

const getFilteredObject = (payload) => Object.entries(payload).reduce((acc, [key, value]) => (value || value === 0 ? { ...acc, [key]: value } : acc), {});

const getPayload = ({ firstName, lastName, mobile, email, vehiclePrice = 0, deposit = 0, assetType, vehicleMake, vehicleModel, vehicleYear, assetCondition }) =>
  getFilteredObject({
    firstName,
    lastName,
    mobile,
    email,
    amount: vehiclePrice - deposit,
    deposit,
    assetType,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    assetCondition,
  });

export const SelfServe: React.FC = () => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const sessionContext: any = useContext(SessionContext);
  const navigate = useNavigate();
  const { mutateAsync: addLead } = partnerApi('addLead');

  const submitButtonClick = (formProps: any) => {
    const { values, validateForm } = formProps;

    validateForm().then(async (error: any) => {
      if (Object.keys(error).length > 0) {
        setFocusToErrorField(error);
        return;
      }

      setIsSubmitting(true);

      try {
        const payload = getPayload(values);
        const response = await addLead(payload);
        sessionContext.setDisplayAddModal(false);

        if (response?.error) {
          toast.error('There was an error when trying to create a lead');
        }

        if (response?.productURL) {
          toast.success(
            <span data-test-id="add-lead-success-toast">
              Your lead has been sent the following <a href={response.productURL}>link</a> to obtain their finance quotes
            </span>,
            {
              duration: 10000,
            }
          );

          const leadId = response.productURL.split('/partial/').pop();

          navigate(`lead/${leadId}`);
        }
      } catch (err) {
        toast.error(`There was an error when trying to create a lead. ${err.toString()}`, {
          duration: 10000,
        });
        setIsSubmitting(false);
      }
    });
  };

  const onCancelClick = () => {
    sessionContext.setDisplayAddModal(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={() => {}}>
      {(formProps): JSX.Element => {
        const { errors, handleBlur, handleChange, touched, values } = formProps;

        return (
          <>
            <DialogTitle>
              <div className={classes.title}>
                <Box sx={{ alignSelf: 'center' }}>
                  <Typography className={classes.titleText} color="textPrimary" variant="h6">
                    New Lead
                  </Typography>
                </Box>
                <Box>
                  <IconButton className={classes.titleCloseIcon} onClick={onCancelClick}>
                    <Close />
                  </IconButton>
                </Box>
              </div>
              <Typography color="textPrimary" variant="body1">
                This will start the quoting process for the customer
              </Typography>
            </DialogTitle>

            <DialogContent dividers={true}>
              <Form>
                <Box>
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    label="First Name"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    margin="dense"
                    inputProps={{
                      'data-test-id': 'input-first-name',
                    }}
                    fullWidth
                  />
                  <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    label="Last Name (Optional)"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    margin="dense"
                    inputProps={{
                      'data-test-id': 'input-last-name',
                    }}
                    fullWidth
                  />
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    label="Email (Optional)"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    margin="dense"
                    inputProps={{
                      'data-test-id': 'input-email',
                    }}
                    fullWidth
                  />
                  <TextField
                    error={Boolean(touched.mobile && errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                    label="Mobile Number"
                    name="mobile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    margin="dense"
                    inputProps={{
                      'data-test-id': 'input-mobile',
                    }}
                    fullWidth
                  />
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel>Vehicle Type (Optional)</InputLabel>
                    <Select
                      label="Vehicle Type (Optional)"
                      name="assetType"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.assetType}
                      inputProps={{
                        'data-test-id': 'select-vehicle-type',
                      }}
                    >
                      <MenuItem value={ASSET_TYPE.CAR} data-test-id={'vehicle-type-car'}>
                        Car
                      </MenuItem>
                      <MenuItem value={ASSET_TYPE.MOTORBIKE} data-test-id={'vehicle-type-motorbike'}>
                        Motorbike
                      </MenuItem>
                      <MenuItem value={ASSET_TYPE.CARAVAN} data-test-id={'vehicle-type-caravan'}>
                        Caravan
                      </MenuItem>
                      <MenuItem value={ASSET_TYPE.OTHER} data-test-id={'vehicle-type-other'}>
                        Other
                      </MenuItem>
                    </Select>
                    <FormHelperText>{errors.assetCondition}</FormHelperText>
                  </FormControl>
                  <TextField
                    error={Boolean(touched.vehiclePrice && errors.vehiclePrice)}
                    helperText={touched.vehiclePrice && errors.vehiclePrice}
                    label="Vehicle Price (Optional)"
                    name="vehiclePrice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    margin="dense"
                    inputProps={{
                      'data-test-id': 'input-vehicle-price',
                    }}
                    fullWidth
                  />
                  <TextField
                    error={Boolean(touched.deposit && errors.deposit)}
                    helperText={touched.deposit && errors.deposit}
                    label="Deposit/Trade-in (Optional)"
                    name="deposit"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    margin="dense"
                    inputProps={{
                      'data-test-id': 'input-deposit',
                    }}
                    fullWidth
                  />
                  <TextField
                    error={Boolean(touched.vehicleMake && errors.vehicleMake)}
                    helperText={touched.vehicleMake && errors.vehicleMake}
                    label="Vehicle Make (Optional)"
                    name="vehicleMake"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    margin="dense"
                    inputProps={{
                      'data-test-id': 'input-vehicle-make',
                    }}
                    fullWidth
                  />
                  <TextField
                    error={Boolean(touched.vehicleModel && errors.vehicleModel)}
                    helperText={touched.vehicleModel && errors.vehicleModel}
                    label="Vehicle Model (Optional)"
                    name="vehicleModel"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    margin="dense"
                    inputProps={{
                      'data-test-id': 'input-vehicle-model',
                    }}
                    fullWidth
                  />
                  <TextField
                    error={Boolean(touched.vehicleYear && errors.vehicleYear)}
                    helperText={touched.vehicleYear && errors.vehicleYear}
                    label="Vehicle Year (Optional)"
                    name="vehicleYear"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    margin="dense"
                    inputProps={{
                      'data-test-id': 'input-vehicle-year',
                    }}
                    fullWidth
                  />
                  <FormControl variant="outlined" margin="dense" error={Boolean(errors.assetCondition)} fullWidth>
                    <InputLabel>Vehicle Condition (Optional)</InputLabel>
                    <Select
                      label="Vehicle Condition (Optional)"
                      name="assetCondition"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.assetCondition}
                      inputProps={{
                        'data-test-id': 'select-vehicle-condition',
                      }}
                    >
                      <MenuItem value={VEHICLE_STATE.NEW} data-test-id={'vehicle-condition-new'}>
                        New
                      </MenuItem>
                      <MenuItem value={VEHICLE_STATE.DEMO} data-test-id={'vehicle-condition-demo'}>
                        Demo
                      </MenuItem>
                      <MenuItem value={VEHICLE_STATE.USED} data-test-id={'vehicle-condition-used'}>
                        Used
                      </MenuItem>
                    </Select>
                    <FormHelperText>{errors.assetCondition}</FormHelperText>
                  </FormControl>
                </Box>
              </Form>
            </DialogContent>

            <DialogActions>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={() => {
                  submitButtonClick(formProps);
                }}
                data-test-id="btn-submit-add-lead"
                disabled={isSubmitting}
              >
                {!isSubmitting ? 'Submit' : ''}
                {isSubmitting && <CircularProgress size="25px" />}
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
};
