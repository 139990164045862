import React, { useState } from 'react';
import { Dialog } from '@mui/material';

import { AddLeadSelector } from './AddLeadSelector';
import { SelfServe } from './SelfServe';
import { useFlags } from 'launchdarkly-react-client-sdk';

const AddLeadModal: React.FC = () => {
  const { referrerLedJourney } = useFlags();
  const [displaySelfServe, setDisplaySelfServe] = useState(!referrerLedJourney);

  return (
    <Dialog open={true} scroll="body">
      {!displaySelfServe && <AddLeadSelector setDisplaySelfServe={setDisplaySelfServe} />}
      {displaySelfServe && <SelfServe />}
    </Dialog>
  );
};

export default AddLeadModal;
