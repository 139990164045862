import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1089,
    backgroundColor: '#FBFBFD',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',

    '@media (max-width: 1090px)': {
      width: '100%',
      height: '100%',
      overflow: 'scroll',
      position: 'relative',
      top: 0,
      left: 0,
      transform: 'none'
    }
  },
  header: {
    background: '#1C242A',
    color: '#fff',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '24px',
    lineHeight: '33px',
    padding: '25px 32px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      flexGrow: '1'
    },

    '& > img': {
      cursor: 'pointer'
    }
  },
  content: {
    padding: 32
  },
  divider: {
    height: '8px',
    borderRadius: '8px',
    marginBottom: '8px'
  },
  section: {},
  sectionLabel: {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: '8px'
  },
  status: {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    margin: 0,
    marginBottom: '8px',

    '& > strong': {
      fontWeight: 600
    },

    '&:last-child': {
      marginBottom: '24px'
    }
  }
}));

export default useStyles;
