import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface StyleProps {
  variant: 'header' | 'sidebar';
}

const useStyles = makeStyles((theme: Theme) => ({
  partnerLogoBox: ({ variant }: StyleProps) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',

    [theme.breakpoints.down('lg')]: {
      display: variant === 'header' ? 'none' : ''
    }
  }),
  userBox: ({ variant }: StyleProps) => ({
    [theme.breakpoints.down('lg')]: {
      display: variant === 'header' ? 'none' : ''
    }
  }),
  userName: {
    color: '#3E3F42',
    lineHeight: '0.9',
    margin: '8px',
    fontSize: '13px',
    fontWeight: 'bold'
  },
  partnerName: {
    color: '#3E3F42',
    lineHeight: '0.9',
    margin: '8px',
    fontSize: '12px'
  },
  partnerSwitcher: {
    color: '#3E3F42',
    lineHeight: '0.9',
    margin: '8px',
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  switcherDropdown: ({ variant }: StyleProps) => ({
    backgroundColor: '#fff',
    color: '#172b4d',
    position: variant === 'header' ? 'absolute' : 'fixed',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 25%)',
    borderRadius: 8,
    marginTop: -5,
    zIndex: 1400,
    width: 260,
    maxHeight: 400,
    left: variant === 'header' ? undefined : 10,
    right: variant === 'header' ? 10 : undefined,
    overflow: 'hidden',
    overflowY: 'scroll',

    '& > ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',

      '& > li': {
        padding: '6px 8px',
        display: 'block',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',

        '&:hover': {
          backgroundColor: '#f6f4f4'
        }
      }
    }
  }),
  searchInput: {
    border: 0,
    outline: 'none',
    borderBottom: '1px solid rgba(0 0 0 / 20%)',
    width: '100%',
    padding: '14px'
  }
}));

export default useStyles;
