import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().matches(/^\D+$/, 'Enter only alphabetical letters').max(15, 'Must be 15 characters or less').required('Please enter a value above'),
  lastName: Yup.string().matches(/^\D+$/, 'Enter only alphabetical letters').max(20, 'Must be 20 characters or less'),
  mobile: Yup.string()
    .max(10, 'Maximum 10 digits')
    .matches(/^04(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$/, 'Please input a valid mobile number starting with 04')
    .required('Please enter a value above'),
  email: Yup.string().email('Please enter valid email addresss'),
  vehicleYear: Yup.string().matches(/^(19|20)\d{2}$/, `Please provide a valid year between 1900 and ${new Date().getFullYear()}`),
  vehiclePrice: Yup.number().when(['deposit'], {
    is: (deposit) => deposit,
    then: Yup.number().moreThan(Yup.ref('deposit'), 'Vehicle Price must be greater than Deposit'),
  }),
  assetCondition: Yup.string(),
});
