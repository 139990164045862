import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Dialog, DialogTitle, DialogContent, IconButton, DialogActions, Button } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

import CheckedCricle from '../../../../assets/img/icon-check-circled.svg';
import { SessionContext } from 'src/contexts/SessionContext';
import { SelectionBox } from './SelectionBox';

const useStyles = makeStyles(() => ({
  titleContainer: { background: '#262627' },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleText: {
    flex: '0 1 auto',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontWeight: 700,
    fontSize: '25px',
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  titleCloseIcon: {
    padding: '0px',
    flex: '0 1 auto',
    marginLeft: 'auto',
  },
  bodyText: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'center',
    padding: '20px',
  },
  selectionContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '20px',
  },
  contentContainer: {
    paddingBottom: '0px',
  },
  submit: {
    padding: '15px',
    fontSize: '18px',
  },
  privacyAgreement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    marginTop: '8px',
    fontWeight: 500,
    cursor: 'pointer',

    '& a': {
      color: 'inherit',
      textDecoration: 'underline',
    },
  },
  privacyUnchecked: {
    width: '16px',
    height: '16px',
    display: 'block',
    border: '1px solid #E2E2E8',
    borderRadius: '9999px',
  },
}));

interface AddLeadSelectorInterface {
  setDisplaySelfServe: (boolean) => void;
}

export const AddLeadSelector: React.FC<AddLeadSelectorInterface> = ({ setDisplaySelfServe }) => {
  const classes = useStyles();
  const sessionContext: any = useContext(SessionContext);
  const navigate = useNavigate();

  const [guidedSelected, setGuidedSelected] = useState(false);
  const [selfSelected, setSelfSelected] = useState(false);

  const onCancelClick = () => {
    sessionContext.setDisplayAddModal(false);
  };

  const submitClick = () => {
    if (selfSelected) {
      setDisplaySelfServe(true);
    }

    if (guidedSelected) {
      sessionContext.setDisplayAddModal(false);
      navigate(`referrer-led-journey`);
    }
  };

  const [privacyAgreed, setPrivacyAgreed] = useState<boolean>(false);
  const disableContinue = (guidedSelected && !privacyAgreed) || (!guidedSelected && !selfSelected);
  const togglePrivacyAgreement = () => setPrivacyAgreed((state) => !state);
  const privacyAgreeIcon = privacyAgreed ? (
    <img src={CheckedCricle} onClick={togglePrivacyAgreement} />
  ) : (
    <span onClick={togglePrivacyAgreement} className={classes.privacyUnchecked} />
  );

  return (
    <Dialog open={true} scroll="body" maxWidth="md">
      <DialogTitle className={classes.titleContainer}>
        <div className={classes.title}>
          <Box sx={{ alignSelf: 'center' }}>
            <Typography className={classes.titleText} color="textPrimary" variant="h6">
              Add Lead
            </Typography>
          </Box>
          <Box>
            <IconButton className={classes.titleCloseIcon} onClick={onCancelClick}>
              <Close />
            </IconButton>
          </Box>
        </div>
      </DialogTitle>

      <DialogContent className={classes.contentContainer}>
        <Typography color="textPrimary" variant="body1" className={classes.bodyText}>
          How would you like to start the quoting process for the customer
        </Typography>
        <Box className={classes.selectionContainer}>
          <SelectionBox
            heading="Guided quote with customer"
            body="Guide the customer through the finance quote questionnaire and instant loan options"
            type="guided"
            selected={guidedSelected}
            setSelected={setGuidedSelected}
            setDeselected={setSelfSelected}
          />
          <SelectionBox
            heading="Customer self-serve quote"
            body="Submit customer details for Driva to guide the customer through the finance quote and loan options"
            type="selfServe"
            selected={selfSelected}
            setSelected={setSelfSelected}
            setDeselected={setGuidedSelected}
          />
        </Box>
        {guidedSelected && (
          <Box className={classes.privacyAgreement}>
            {privacyAgreeIcon}
            <div>
              <span onClick={togglePrivacyAgreement}>The customer agrees to our</span>{' '}
              <a href="https://www.driva.com.au/legal/" target="_blank">
                privacy policy
              </a>
            </div>
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          data-test-id="btn-submit-add-lead"
          disabled={disableContinue}
          className={classes.submit}
          onClick={submitClick}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
