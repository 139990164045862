import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { auth0Config } from '../config';
import type { User } from './types/user';
import axios from '../lib/axios';
import logo from '../assets/img/logo2.svg';

let auth0Client: Auth0Client | null = null;

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  isLoading: boolean;
}
export interface AuthContextValue extends State {
  platform: 'Auth0';
  loginWithPopup: (options?: any) => Promise<void>;
  logout: () => void;
  updateUser: (payload: User) => Promise<void>;
}
interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isLoading: true,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      isLoading: false,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      isLoading: false,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    isLoading: false,
  }),
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
});

const getUserObject = async (): Promise<any> => {
  const response = await axios(`${process.env.REACT_APP_SERVER_BASE_URL}/api/auth`);

  return response.data.result;
};

const getUserPayload = async (user: any): Promise<User> => {
  const { picture, email } = user;
  const userObj = await getUserObject();
  const {
    fullName,
    partner: { partnerId, name: partnerName, logo: partnerLogo },
    permissions,
  } = userObj;
  return {
    id: userObj.id,
    avatar: picture,
    email: email,
    name: fullName,
    partner: {
      uuid: partnerId,
      name: partnerName ? partnerName : 'Driva',
      logo: partnerLogo ? partnerLogo : logo,
    },
    permissions: permissions,
  };
};

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin,
          ...auth0Config,
          useRefreshTokens: true,
          cacheLocation: 'localstorage',
          audience: process.env.REACT_APP_SERVER_BASE_URL,
        });
        const jwtToken = await auth0Client.getTokenSilently();
        axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          const userPayload = await getUserPayload(user);
          // Here you should extract the complete user profile to make it
          // available in your entire app.
          // The auth state only provides basic information.

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: userPayload,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const loginWithPopup = async (): Promise<void> => {
    await auth0Client.loginWithPopup();
    const jwtToken = await auth0Client.getTokenSilently();
    axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      const userPayload = await getUserPayload(user);
      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.

      dispatch({
        type: 'LOGIN',
        payload: {
          user: userPayload,
        },
      });
    }
  };

  const logout = async (): Promise<void> => {
    await auth0Client.logout({
      federated: true,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: window.location.origin,
    });

    dispatch({
      type: 'LOGOUT',
    });
  };

  const updateUser = async (payload): Promise<void> => {
    const user = await getUserPayload(payload);

    return new Promise((resolve, reject) => {
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });

      resolve();
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Auth0',
        loginWithPopup,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
