import { Avatar, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import arrowRightLight from '../../../assets/img/arrowRightLight.svg';
import arrowRightDark from '../../../assets/img/arrowRightDark.svg';
import useStyles from './PipelineColumn.styles';
import { convertToCurrency } from 'src/utils/helper';

interface PipelineColumnInterface {
  title: string;
  count: number;
  colour: 'BLUE' | 'GREEN' | 'YELLOW' | 'RED';
  showMorePath: string;
  assigneeId?: string;
  leads: Array<any>;
  assignLead: any;
}

const PipelineColumn = ({ title, count, colour, showMorePath, assigneeId, leads, assignLead }: PipelineColumnInterface) => {
  let hexColour;
  switch (colour) {
    case 'BLUE':
      hexColour = '#3496AA';
      break;
    case 'GREEN':
      hexColour = '#A1E2C1';
      break;
    case 'YELLOW':
      hexColour = '#FFF49C';
      break;
    case 'RED':
      hexColour = '#FFC3C3';
      break;
  }

  const classes = useStyles({ colour: hexColour });
  const maxLeads = 25;
  const hiddenLeadsCount = leads.length - maxLeads;
  const showMoreLeadsButton = hiddenLeadsCount > 0;
  const moreLeadsButton = (
    <Link className={classes.showMoreLeads} to={showMorePath} state={{ assignee: assigneeId }}>
      <span>Show {hiddenLeadsCount} more cases</span>
      <img src={arrowRightLight} alt="" />
    </Link>
  );

  const leadCards = leads?.slice(0, maxLeads).map((lead) => (
    <div className={classes.lead} key={lead.leadId} onClick={() => assignLead(lead)}>
      <div className={classes.leadTitle}>
        <span>
          {lead.firstName} {lead.lastName}
        </span>
        <Avatar
          sx={{
            width: 30,
            height: 30,
            float: 'right',
            top: 15,
            backgroundColor: '#F5F5F8',
            color: '#1C242A',
            fontSize: '12px',
          }}
        >
          {lead.assignee?.fullName && `${lead.assignee.fullName?.split(' ')[0][0]}${lead.assignee.fullName?.split(' ')[1][0]}`}
          {!lead.assignee?.fullName && '-'}
        </Avatar>
      </div>
      <div className={classes.leadContent}>
        <div className={classes.loanAmount}>Loan amount: {convertToCurrency(lead.loanAmount, true).replace(' ', '')}</div>
        <div className={classes.vehicle}>
          {lead.vehicleMake && (
            <>
              <span>{lead.vehicleYear}</span>
              <span>{lead.vehicleMake}</span>
              <span>{lead.vehicleModel}</span>
            </>
          )}
          {!lead.vehicleMake && <span>Vehicle not specified</span>}
        </div>
        <div className={classes.status}>
          <div className={classes.statusCircle}></div>
          <span>{lead.status}</span>
          <div className={classes.arrow}>
            <img src={arrowRightDark} alt="" />
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
      <Chip
        label={count}
        sx={{
          backgroundColor: colour === 'BLUE' ? `${hexColour}50` : hexColour,
          minWidth: 50,
          height: 20,
          borderRadius: '5px',
          padding: '2px',
          color: '#1C242A',
          fontWeight: 400,
        }}
      />
      <div className={classes.leads}>
        {leadCards}
        {showMoreLeadsButton && moreLeadsButton}
      </div>
    </div>
  );
};

export default PipelineColumn;
