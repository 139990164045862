import { useMutation, useQuery } from 'react-query';
import axios from '../lib/axios';
import { apiList } from './paths';

interface IParam {
  [key: string]: string | string[] | number;
}

interface IQueryParam {
  [key: string]: string | number | string[]; 
}

interface IHeaderParam {
  [key: string]: string | number | boolean; 
}


const callFunc = async (funcName: string, params?: IParam, data?: any) => {
  try {
    const { url, method, headers } = apiList[funcName];
    const queryParams: IParam = {};
    const headerParams: IParam = {};

    const requestUrl = params
      ? Object.entries(params).reduce((reducedUrl, [key, value]) => {
          if (reducedUrl.indexOf(`:${key}`) >= 0) {
            return reducedUrl.replace(`:${key}`, value as string);
          }

          if (headers?.indexOf(key) >= 0) {
            headerParams[key] = value;
          } else {
            queryParams[key] = value;
          }

          return reducedUrl;
        }, url)
      : url;
      const requestConfig = {
        url: `${process.env.REACT_APP_SERVER_BASE_URL}${requestUrl}`,
        method: method, 
        data: data,
        params: queryParams as IQueryParam, 
        headers: headerParams as IHeaderParam 
    };
    
    const res = await axios(requestConfig);

    return res.data.result;
  } catch (err) {
    console.log(`${funcName}: ${err.message}`);
    throw new Error(err?.response?.data?.message);
  }
};

const Query = (funcName: string, enabled: boolean, params?: IParam) => {
  const {
    data,
    error,
    failureCount,
    isError,
    isFetchedAfterMount,
    isFetching,
    isIdle,
    isLoading,
    isPlaceholderData,
    isPreviousData,
    isStale,
    isSuccess,
    refetch,
    remove,
    status
  } = useQuery([funcName, params], () => callFunc(funcName, params), {
    // disable retry to avoid 4 times API call if failed
    retry: false,
    // disable refetchOnWindowFocus to avoid unnecessary API call on focus
    refetchOnWindowFocus: false,
    enabled
  });

  return {
    data,
    error,
    failureCount,
    isError,
    isFetchedAfterMount,
    isFetching,
    isIdle,
    isLoading,
    isPlaceholderData,
    isPreviousData,
    isStale,
    isSuccess,
    refetch,
    remove,
    status
  };
};

const Mutation = (funcName: string, params?: IParam) => {
  const { data, error, isError, isIdle, isLoading, isPaused, isSuccess, mutate, mutateAsync, reset, status } = useMutation((postData: any) =>
    callFunc(funcName, params, postData)
  );

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status
  };
};

const partnerApi = (funcName: string, params?: IParam, enabled: boolean = true): any => {
  if (!(funcName in apiList)) {
    return false;
  }

  const { method } = apiList[funcName];
  if (method === 'get') {
    return Query(funcName, enabled, params);
  }

  return Mutation(funcName, params);
};

export default partnerApi;
