import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Container, Grid, Typography, LinearProgress } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import toast from 'react-hot-toast';

import ChevronRightIcon from '../../template/material-kit-pro/icons/ChevronRight';
import useSettings from '../../template/material-kit-pro/hooks/useSettings';
import gtm from '../../template/material-kit-pro/lib/gtm';
import partnerApi from '../../api';
import useAuth from '../../hooks/useAuth';
import PermissionProvider from '../Permission/PermissionProvider';
import { ROUTES } from '../../routes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Pipeline from '../DealershipLeads/Pipeline/Pipeline';
import PipelineStatusSummary from 'src/components/PipelineStatusSummary/PipelineStatusSummary';
import { retrievePersistentDateFilter } from '../../utils/helper';

const MyLeads: FC = () => {
  const { settings } = useSettings();
  const auth = useAuth();
  const [showStatusSummary, setShowStatusSummary] = useState<boolean>(false);

  const { logout } = auth;

  const { from, to } = retrievePersistentDateFilter('myleads');

  const [displayRange, setDisplayRange] = useState<DateRange<Date>>([DateTime.fromISO(from).toJSDate(), DateTime.fromISO(to).toJSDate()]);

  const setPersistentDisplayRange = (newValue) => {
    localStorage.setItem('myleads.from', DateTime.fromJSDate(newValue[0]).setLocale('au').toFormat('yyyy-MM-dd'));
    localStorage.setItem('myleads.to', DateTime.fromJSDate(newValue[1]).setLocale('au').toFormat('yyyy-MM-dd'));
    localStorage.setItem('lastFilterSetDate', DateTime.local().toISODate());
    setDisplayRange(newValue);
  };

  const periodStart = DateTime.fromJSDate(displayRange[0]).setLocale('au');
  const periodEnd = DateTime.fromJSDate(displayRange[1]).setLocale('au').endOf('day');

  const {
    data: allLeads,
    isLoading,
    error,
    refetch: refetchLeads,
  } = partnerApi(
    'getLeads',
    {
      periodStart: periodStart.toISO(),
      periodEnd: periodEnd.toISO(),
    },
    true
  );

  const { forceUserLogout } = useFlags();

  const leads = allLeads?.filter((lead) => lead.assignee && lead.assignee.id === auth.user.id) ?? [];

  useEffect(() => {
    async function logoutUser() {
      await logout();
    }

    gtm.push({ event: 'page_view' });

    if (error) {
      if (error.message === 'INSUFFICIENT_SCOPE_ERROR' && forceUserLogout) {
        logoutUser();
      } else {
        toast.error('There was an error when trying retrieve leads', {
          duration: 10000,
        });
        console.error(error);
      }
    }
  }, [error, forceUserLogout]);

  const statusSummaryOnClose = () => setShowStatusSummary(false);
  const statusSummaryBtnOnClick = () => setShowStatusSummary(!showStatusSummary);

  return (
    <PermissionProvider routeName={ROUTES.LEADS}>
      <Helmet>
        <title>Leads - Driva Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                My Leads
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
                <Typography color="textSecondary" variant="subtitle2">
                  Listed are leads assigned to you. Go to group leads to see overall progress pipeline.
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {isLoading && <LinearProgress sx={{ mt: 3, mb: 3 }} />}
            <Pipeline
              displayRange={displayRange}
              setDisplayRange={setPersistentDisplayRange}
              leads={leads}
              refetchLeads={refetchLeads}
              withSalespersonFilter={false}
              assigneeId={auth?.user?.id}
            />
          </Box>
        </Container>
      </Box>
      <PipelineStatusSummary isOpen={showStatusSummary} onClose={statusSummaryOnClose} />
      <PipelineStatusSummary.Button onClick={statusSummaryBtnOnClick} />
    </PermissionProvider>
  );
};

export default MyLeads;
