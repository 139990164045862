import { makeStyles } from '@mui/styles';

interface StyleProps {
  open: boolean;
}

const useStyles = makeStyles(() => ({
  root: ({ open = true }: StyleProps) => ({
    position: 'fixed',
    bottom: '40px',
    right: open ? 0 : -160,
    transition: 'right 0.5s',
    background: '#E2EDF9',
    border: '1px solid #C5DAF1',
    padding: '16px',
    width: '245px',
    height: '60px',
    borderTopLeftRadius: '50px',
    borderBottomLeftRadius: '50px',
    display: 'flex',
    alignItems: 'flex-start'
  }),
  closer: {
    color: '#B7CCE3',
    paddingTop: '2px',
    cursor: 'pointer'
  },
  icon: {
    paddingTop: '2px',
    marginLeft: '5px',
    cursor: 'pointer',

    '& > img': {
      height: '24px',
      width: '24px'
    }
  },
  label: ({ open = true }: StyleProps) => ({
    opacity: open ? 1 : 0,
    transition: 'opacity 0.5s',
    paddingTop: '2px',
    color: '#3E3F42',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    marginLeft: '8px'
  })
}));

export default useStyles;
