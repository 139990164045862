import { FC, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Toolbar, Button, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Menu from '@mui/icons-material/Menu';
import { experimentalStyled } from '@mui/material/styles';

import logoBlack from '../../assets/img/logoBlack.svg';
import logoWhite from '../../assets/img/logoWhite.svg';
import { SessionContext } from '../../contexts/SessionContext';
import Close from '@mui/icons-material/Close';
import PartnerSwitcher from 'src/components/PartnerSwitcher/PartnerSwitcher';

const useStyles = makeStyles((theme: Theme) => {
  return {
    toolbar: {
      minHeight: '64px',
    },
    menuBox: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
    },
    menuButton: {
      paddingLeft: '0px',
      color: 'primary',
    },
    logoBox: {
      display: 'flex',
    },
    filler: {
      flexGrow: 1,
      ml: 2,
    },
  };
});

const MainNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
    background: '#ffffff',
    borderBottom: '2px solid #1C242A',
    [theme.breakpoints.down('lg')]: {
      backgroundColor: '#1C242A',
    },
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: '2px solid #1C242A',
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const MainNavbar: FC = ({ ...other }) => {
  const { isSidebarMobileOpen, setIsSidebarMobileOpen } = useContext(SessionContext);
  const classes = useStyles();

  const onSidebarMobileOpen = (): void => {
    setIsSidebarMobileOpen(!isSidebarMobileOpen);
  };

  const logoVariant = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg')) ? logoWhite : logoBlack;

  const menuIcon = isSidebarMobileOpen ? <Close fontSize="small" /> : <Menu fontSize="small" />;

  return (
    <MainNavbarRoot {...other}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.logoBox}>
          <RouterLink to="/">
            <img alt="Logo" src={logoVariant} />
          </RouterLink>
        </Box>
        <Box className={classes.filler} />
        <PartnerSwitcher />
        <Box className={classes.menuBox}>
          <Button endIcon={menuIcon} variant="text" className={classes.menuButton} onClick={onSidebarMobileOpen} data-test-id="btn-menu">
            Menu
          </Button>
        </Box>
      </Toolbar>
    </MainNavbarRoot>
  );
};

export default MainNavbar;
